import React from 'react';
import {
  ChakraProvider,
  Text,
  VStack,
  Image,
  Flex,
  theme,
} from '@chakra-ui/react';
import logo from './logo.png';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Flex
        alignItems={'center'}
        justifyContent="center"
        flexDir="column"
        fontSize="xl"
      >
        <Flex mt={32} mb={10}>
          <Image src={logo} />
        </Flex>
        <VStack
          spacing={10}
          flexDir={'column'}
          border="1px"
          borderColor={'#D7E446'}
          borderRadius={4}
          p={8}
        >
          <Text color="white">Ünvan: Əhməd Cəmil 158</Text>
          <Text color="white">Əlaqə: (+994) 55 720 68 48</Text>
          <Text color="white">Hər bir istəyə uyğun əlbisə tikilir.</Text>
          <Text color="white">Satış və ya kirayə paltarlar</Text>
        </VStack>
      </Flex>
    </ChakraProvider>
  );
}

export default App;
